import { Suspense, lazy } from "react";
import { BrowserRouter as Router, Navigate, Outlet, Route, Routes } from "react-router-dom";

import TokenHelper from "./utils/helpers/tokenHelper";
import PageLoader from "./components/loaders/PageLoader";
import { MenuType, SubMenuType } from "./utils/helpers/constants";

const Home = lazy(() => import('./pages/dashboard/home/Home'));
const Login = lazy(() => import("./pages/authentication/Login"));
const ForgotPassword = lazy(() => import("./pages/authentication/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/authentication/ResetPassword"));

const Taxes = lazy(() => import("./pages/dashboard/configurations/taxes/Taxes"));
const States = lazy(() => import("./pages/dashboard/configurations/states/States"));
const AddTax = lazy(() => import("./pages/dashboard/configurations/taxes/atom/AddTax"));
const TaxInfo = lazy(() => import("./pages/dashboard/configurations/taxes/atom/TaxInfo"));
const AddState = lazy(() => import("./pages/dashboard/configurations/states/atom/AddState"));
const Countries = lazy(() => import("./pages/dashboard/configurations/countries/Countries"));
const StateInfo = lazy(() => import("./pages/dashboard/configurations/states/atom/StateInfo"));
const Currencies = lazy(() => import("./pages/dashboard/configurations/currencies/Currencies"));
const AddCountry = lazy(() => import("./pages/dashboard/configurations/countries/atom/AddCountry"));
const CountryInfo = lazy(() => import("./pages/dashboard/configurations/countries/atom/CountryInfo"));
const Measurements = lazy(() => import("./pages/dashboard/configurations/measurements/Measurements"));
const AddCurrency = lazy(() => import("./pages/dashboard/configurations/currencies/atom/AddCurrency"));
const CurrencyInfo = lazy(() => import("./pages/dashboard/configurations/currencies/atom/CurrencyInfo"));
const PaymentMethod = lazy(() => import("./pages/dashboard/configurations/paymentMethod/PaymentMethod"));
const PaymentService = lazy(() => import("./pages/dashboard/configurations/paymentService/PaymentService"));
const AddMeasurement = lazy(() => import("./pages/dashboard/configurations/measurements/atom/AddMeasurement"));
const MeasurementInfo = lazy(() => import("./pages/dashboard/configurations/measurements/atom/MeasurementInfo"));
const AddPaymentMethod = lazy(() => import("./pages/dashboard/configurations/paymentMethod/atom/AddPaymentMethod"));
const ImportMeasurement = lazy(() => import("./pages/dashboard/configurations/measurements/atom/ImportMeasurement"));
const PaymentMethodInfo = lazy(() => import("./pages/dashboard/configurations/paymentMethod/atom/PaymentMethodInfo"));
const AddPaymentService = lazy(() => import("./pages/dashboard/configurations/paymentService/atom/AddPaymentService"));

const Roles = lazy(() => import("./pages/dashboard/users/roles/Roles"));
const UserProfile = lazy(() => import("./pages/dashboard/users/profile/UserProfile"));
const UserRoleInfo = lazy(() => import("./pages/dashboard/users/roles/atom/UserRoleInfo"));
const CreateUserRole = lazy(() => import("./pages/dashboard/users/roles/atom/CreateUserRole"));
const UserProfileInfo = lazy(() => import("./pages/dashboard/users/profile/atom/UserProfileInfo"));
const CreateUserProfile = lazy(() => import("./pages/dashboard/users/profile/atom/CreateUserProfile"));

const Messaging = lazy(() => import("./pages/dashboard/messaging/Messaging"));
const Plans = lazy(() => import("./pages/dashboard/subscriptions/plans/Plans"));
const Addons = lazy(() => import("./pages/dashboard/subscriptions/addons/Addons"));
const PlanInfo = lazy(() => import("./pages/dashboard/subscriptions/plans/atom/PlanInfo"));
const AddonInfo = lazy(() => import("./pages/dashboard/subscriptions/addons/atom/AddonInfo"));
const CreatePlan = lazy(() => import("./pages/dashboard/subscriptions/plans/atom/CreatePlan"));
const CreateAddon = lazy(() => import("./pages/dashboard/subscriptions/addons/atom/CreateAddon"));

const Tenancy = lazy(() => import("./pages/dashboard/tenancy/tenants/Tenancy"));
const RoleManager = lazy(() => import("./pages/dashboard/tenancy/roleManager/RoleManager"));
const TenancyInfo = lazy(() => import("./pages/dashboard/tenancy/tenants/atom/info/TenancyInfo"));
const RoleManagerInfo = lazy(() => import("./pages/dashboard/tenancy/roleManager/atom/RoleManagerInfo"));
const CreateRoleManager = lazy(() => import("./pages/dashboard/tenancy/roleManager/atom/CreateRoleManager"));
const CreateTenantAccount = lazy(() => import("./pages/dashboard/tenancy/tenants/atom/CreateTenantAccount"));

const ExpenseCategories = lazy(() => import("./pages/dashboard/configurations/expenseCategories/ExpenseCategories"));
const AddNotification = lazy(() => import("./pages/dashboard/configurations/notificationService/atom/AddNotification"));
const PaymentServiceInfo = lazy(() => import("./pages/dashboard/configurations/paymentService/atom/PaymentServiceInfo"));
const NotificationInfo = lazy(() => import("./pages/dashboard/configurations/notificationService/atom/NotificationInfo"));
const NotificationService = lazy(() => import("./pages/dashboard/configurations/notificationService/NotificationService"));
const AddExpenseCategory = lazy(() => import("./pages/dashboard/configurations/expenseCategories/atom/AddExpenseCategory"));
const ExpenseCategoryInfo = lazy(() => import("./pages/dashboard/configurations/expenseCategories/atom/ExpenseCategoryInfo"));
const ImportExpenseCategory = lazy(() => import("./pages/dashboard/configurations/expenseCategories/atom/ImportExpenseCategory"));

const Error404 = lazy(() => import("./pages/misc/Error404"));
const Error403 = lazy(() => import("./pages/misc/Error403"));


const App = () => {

	const AuthenticatedRoutes = () => {
		if (!TokenHelper.checkIfLoggedIn()) return <Navigate to={`/${MenuType.AUTH}/${SubMenuType.LOGIN}`} replace />;
		else return <Outlet />
	};

	return (
		<Router>
			<Suspense fallback={<PageLoader />}>
				<Routes>
					<Route path={MenuType.AUTH} element={<Outlet />}>
						<Route index path={SubMenuType.LOGIN} element={<Login />} />
						<Route path={SubMenuType.RESET_PASSWORD} element={<ResetPassword />} />
						<Route path={SubMenuType.FORGOT_PASSWORD} element={<ForgotPassword />} />
					</Route>
					<Route element={<AuthenticatedRoutes />}>
						{['/', MenuType.HOME, 'dashboard'].map(path => <Route path={path} key={path} element={<Home />} />)}
						<Route path={MenuType.USERS}>
							<Route path={SubMenuType.PROFILE} element={<Outlet />}>
								<Route index element={<UserProfile />} />
								<Route path="create" element={<CreateUserProfile />} />
								<Route path=":name" element={<UserProfileInfo />} />
							</Route>
							<Route path={SubMenuType.ROLES} element={<Outlet />}>
								<Route index element={<Roles />} />
								<Route path="create" element={<CreateUserRole />} />
								<Route path=":name" element={<UserRoleInfo />} />
							</Route>
						</Route>
						<Route path={MenuType.MESSAGING} element={<Messaging />} />

						<Route path={MenuType.SUBSCRIPTIONS}>
							<Route path={SubMenuType.ADDONS} element={<Outlet />}>
								<Route index element={<Addons />} />
								<Route path="create" element={<CreateAddon />} />
								<Route path=":name" element={<AddonInfo />} />
							</Route>
							<Route path={SubMenuType.PLANS} element={<Outlet />}>
								<Route index element={<Plans />} />
								<Route path="create" element={<CreatePlan />} />
								<Route path=":name" element={<PlanInfo />} />
							</Route>
						</Route>

						<Route path={MenuType.TENANCY}>
							<Route path={SubMenuType.TENANTS_ACCOUNT} element={<Outlet />}>
								<Route index element={<Tenancy />} />
								<Route path="create" element={<CreateTenantAccount />} />
								<Route path=":name" element={<TenancyInfo />} />
							</Route>
							<Route path={SubMenuType.ROLE_MANAGER} element={<Outlet />}>
								<Route index element={<RoleManager />} />
								<Route path="create" element={<CreateRoleManager />} />
								<Route path=":name" element={<RoleManagerInfo />} />
							</Route>
						</Route>

						<Route path={MenuType.CONFIGS}>
							<Route path={SubMenuType.NOTIFICATION_SERVICE} element={<Outlet />}>
								<Route index element={<NotificationService />} />
								<Route path="create" element={<AddNotification />} />
								<Route path=":name" element={<NotificationInfo />} />
							</Route>
							<Route path={SubMenuType.PAYMENT_SERVICE} element={<Outlet />}>
								<Route index element={<PaymentService />} />
								<Route path="create" element={<AddPaymentService />} />
								<Route path=":name" element={<PaymentServiceInfo />} />
							</Route>
							<Route path={SubMenuType.COUNTRIES} element={<Outlet />}>
								<Route index element={<Countries />} />
								<Route path="create" element={<AddCountry />} />
								<Route path=":name" element={<CountryInfo />} />
							</Route>
							<Route path={SubMenuType.STATES} element={<Outlet />}>
								<Route index element={<States />} />
								<Route path="create" element={<AddState />} />
								<Route path=":name" element={<StateInfo />} />
							</Route>
							<Route path={SubMenuType.TAXES} element={<Outlet />}>
								<Route index element={<Taxes />} />
								<Route path="create" element={<AddTax />} />
								<Route path=":name" element={<TaxInfo />} />
							</Route>
							<Route path={SubMenuType.PAYMENT_METHOD} element={<Outlet />}>
								<Route index element={<PaymentMethod />} />
								<Route path="create" element={<AddPaymentMethod />} />
								<Route path=":name" element={<PaymentMethodInfo />} />
							</Route>
							<Route path={SubMenuType.CURRENCIES} element={<Outlet />}>
								<Route index element={<Currencies />} />
								<Route path="create" element={<AddCurrency />} />
								<Route path=":name" element={<CurrencyInfo />} />
							</Route>
							<Route path={SubMenuType.EXPENSE_CATEGORIES} element={<Outlet />}>
								<Route index element={<ExpenseCategories />} />
								<Route path="create" element={<AddExpenseCategory />} />
								<Route path=":name" element={<ExpenseCategoryInfo />} />
								<Route path="import" element={<ImportExpenseCategory />} />
							</Route>
							<Route path={SubMenuType.MEASUREMENTS} element={<Outlet />}>
								<Route index element={<Measurements />} />
								<Route path="create" element={<AddMeasurement />} />
								<Route path=":name" element={<MeasurementInfo />} />
								<Route path="import" element={<ImportMeasurement />} />
							</Route>
						</Route>
					</Route>
					<Route path="*" element={<Error404 />} />
					<Route path="/403" element={<Error403 />} />
				</Routes>
			</Suspense>
		</Router>
	);
}

export default App;
