import { read, utils } from 'xlsx';
import { getBaseUrlEnv } from '../services/axios.service';
import { differenceInDays, endOfDay, format, formatDistance, isValid, startOfDay } from 'date-fns';
import { Environment, SortOptions, SubscriptionDurationOption } from './constants';


export const setKey = key => {
	const env = getBaseUrlEnv() === Environment.PRODUCTION ? Environment.PRODUCTION : Environment.SANDBOX;
	return `ADMIN_${env}-${key}`;
}

/*** ARRANGED IN ALPHABETICAL ORDER ***/
export const convertJSONtoCSV = async (payload) => {
	let data = "", header;
	for (let i = 0; i < payload.length; i++) {
		let result = flattenTextObject(payload[i]);
		let row = '';

		for (const index in result) {
			if (row !== '') row += ',';
			row += `"${result[index].toString()}"`;
		}

		if (i === 0) header = Object.keys(result);
		data += `${row}\r\n`;
	}
	header = Object.keys(header).map(key => toTitleCase(header[key]).toUpperCase());
	return `${header}\r\n${data}`;
};

export const exportCSVData = (data, fileName, type) => {
	const blob = new Blob([data], { type });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName;
	a.click();
	window.URL.revokeObjectURL(url);
}

export const fileToBase64 = event => {
	let files = event;
	if (event && ((event.dataTransfer && event.dataTransfer.files) || (event.target && event.target.files))) {
		return new Promise((resolve, reject) => {
			if (event.dataTransfer) files = event.dataTransfer.files;
			else if (event.target) files = event.target.files;
			const reader = new FileReader();
			reader.onload = () => resolve(reader['result']);
			reader.onerror = (error) => reject(error);
			reader.readAsDataURL(files[0]);
		});
	} else return null;
};

export const filterStatesByCountry = (payloadData, businessCountry) => {
	if (!businessCountry["country"]) return payloadData;
	return payloadData.filter(item => item["country"] === businessCountry["country"]);
};

const flattenBooleanJSON = (permissions) => {
	let toReturn = {};

	for (let key in permissions) {
		if (!permissions.hasOwnProperty(key)) continue;

		if (!permissions[key]) toReturn[key] = false;
		if ((typeof permissions[key]) == 'object' && permissions[key] !== null) {
			let flatObject = flattenBooleanJSON(permissions[key]);
			for (let subKey in flatObject) {
				if (!flatObject.hasOwnProperty(subKey)) continue;
				if (flatObject[subKey] === 'true') toReturn[key + '.' + subKey] = true;
				else if (flatObject[subKey] === 'false') toReturn[key + '.' + subKey] = false;
				else toReturn[key + '.' + subKey] = flatObject[subKey];
			}
		} else {
			if (permissions[key] === 'true') toReturn[key] = true;
			else if (permissions[key] === 'false') toReturn[key] = false;
			else toReturn[key] = permissions[key];
		}
	}
	return toReturn;
}

export const flattenTextObject = (obj) => {
	let result = {};

	for (const key in obj) {

		if (!obj[key]) result[key] = "_"

		else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
			let subFlatObject = flattenTextObject(obj[key]);
			for (const subkey in subFlatObject) {
				if (!subFlatObject[subkey]) result[key + "_" + subkey] = "_"
				else result[key + "_" + subkey] = subFlatObject[subkey];
			}
		}
		else if (typeof obj[key] === "object" && Array.isArray(obj[key])) {
			for (const arrElement of obj[key]) {
				let subFlatObject = flattenTextObject(arrElement);

				for (const subkey in arrElement) {
					if (!subFlatObject[subkey]) result[key + "_" + subkey] = "_"
					else result[key + "_" + subkey] = subFlatObject[subkey];
				}
			}
		} else {
			result[key] = obj[key];
		}

	}
	return result;
};

export const formatNumber = (value , isDecimal) => {
	let numFormat = Intl.NumberFormat('en-US', {style: "decimal", minimumFractionDigits: isDecimal ? 2 : 0, maximumFractionDigits: 2});
	return numFormat.format(value);
};

export const formatRoles = payloadData => {
	return payloadData.map(item => ({
		...item,
		label: toTitleCase(item.name),
		value: item.id
	}));
};

export const formatSubscriptionData = payload => {
	let planCost = (payload['monthly_pricing'] && !isNaN(payload['monthly_pricing']) && payload['subscription_duration_type'] === SubscriptionDurationOption.MONTHLY) ? payload['monthly_pricing']
		: (payload['yearly_pricing'] && !isNaN(payload['yearly_pricing']) && payload['subscription_duration_type'] === SubscriptionDurationOption.YEARLY) ? payload['yearly_pricing'] : "0.00",
		totalPricing = parseFloat(planCost),
		formattedData = [
			[{ text: 'Title', style: 'tableHeader' }, { text: 'Type', style: 'tableHeader' }, { text: 'Pricing', style: 'tableHeader' }],
			[
				{ text: `${payload['plan'] ? payload['plan'] : "-"}${payload['mode'] ? ` - ${payload['mode']}` : ""}`, style: 'tableContent' },
				{ text: 'Subscription', style: 'tableContent' },
				{ text: `${(payload && payload['currency_details'] && payload['currency_details']['symbol']) ? payload['currency_details']['symbol'] : ""}${(payload['monthly_pricing'] && payload['subscription_duration_type'] === SubscriptionDurationOption.MONTHLY) ? `${formatNumber(planCost, true)}/m` : (payload['yearly_pricing'] && payload['subscription_duration_type'] === SubscriptionDurationOption.YEARLY) ? `${formatNumber(planCost, true)}/y` : "0.00"}`, style: 'tableContent' },]
		];

	if (payload['addons_details'] && payload['addons_details'].length > 0) {
		for (let addon of payload['addons_details']) {
			let content = [{ text: '', style: 'tableContent' }, { text: 'Addon', style: 'tableContent' }, { text: '', style: 'tableContent' }];

			content[0]["text"] = (addon && addon["name"]) ? toTitleCase(addon["name"]) : "-";
			let addonAmount = (addon['monthly_pricing'] && addon['addon_duration_type'] === SubscriptionDurationOption.MONTHLY) ? `${isNaN(addon['monthly_pricing']) ?"0.00" : addon['monthly_pricing']}`
				: (addon['yearly_pricing'] && addon['addon_duration_type'] === SubscriptionDurationOption.YEARLY) ? `${isNaN(addon['yearly_pricing']) ? "0.00" : addon['yearly_pricing']}` : "0.00"
			content[2]["text"] = `${(payload && payload['currency_details'] && payload['currency_details']['symbol']) ? payload['currency_details']['symbol'] : ""}${addonAmount}${addon['addon_duration_type'] === SubscriptionDurationOption.MONTHLY ? "/m" : "/y"}`;
			totalPricing = parseFloat(totalPricing) + parseFloat(addonAmount);
			formattedData = [...formattedData, content]
		}
	}

	let footer = [
		{ colSpan: 2, rowSpan: 1, text: ['Status: ', { text: `${payload["subscription_payment_status"] === "PENDING" ? 'Pending Payment' : 'Paid Successful'}`, color: `${payload["subscription_payment_status"] === "PENDING" ? '#F3A218' : '#0F973D'}` }], style: 'tableContent' },
		"",
		{ text: ["Total: ", { text: `${(payload && payload['currency_details'] && payload['currency_details']['symbol']) ? payload['currency_details']['symbol'] : ""}${formatNumber(totalPricing, true)}`, color: "#000000", bold: true }], style: 'tableContent' }
	]


	formattedData = [...formattedData, footer]

	return formattedData;
};

export const getBase64ImageFromURL = (url) => {
	return new Promise((resolve, reject) => {
		let img = new Image();
		img.setAttribute("crossOrigin", "anonymous");
		img.onload = () => {
			const canvas = document.createElement("canvas");
			canvas.width = img.width;
			canvas.height = img.height;
			const ctx = canvas.getContext("2d");
			ctx.drawImage(img, 0, 0);
			const dataURL = canvas.toDataURL("image/png");
			resolve(dataURL);
		};
		img.onerror = (error) => reject(error);
		img.src = url;
	});
};

export const getDateDifference = (date) => {
	if (!date || !isValid(parseDate(date))) return format(parseDate(), "d LLL, yyyy")
	else if (differenceInDays(parseDate(), parseDate(date)) < 7) return `${formatDistance(parseDate(date), parseDate(), { includeSeconds: true })} ago`
	else return format(parseDate(date), "d LLL, yyyy")
};

export const getMonthName = (value) => {
	const month = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
	return month[value];
};

export const getUniqueURL = (userObj) => {
	return (userObj && userObj["tenant_details"] && userObj["tenant_details"]["unique_url"]) ? userObj["tenant_details"]["unique_url"] : ""
};

export const getWeek = (difference) => {
	let date = parseDate();
	date.setDate(date.getDate() - date.getDay() + difference);
	return date;
};

export const getWeekRange = (startValue) => {
	const startDate = startOfDay(getWeek(startValue)).toISOString();
	const endDate = endOfDay(getWeek(startValue + 6)).toISOString();

	return { startDate, endDate }
};

export const getYear = () => {
	let date = parseDate();
	return date.getFullYear();
};

export const isAllSelected = (state) => {
	return Object.values(flattenBooleanJSON(state)).every(Boolean);
}

export const parseDate = (dateString) => {
	if(dateString) return new Date(dateString);
	else return new Date();
}

export const processSheet = file => {
	let reader = new FileReader();
	return new Promise((resolve, reject) => {
		reader.onload = event => {
			const wb = read(event.target.result);
			const sheets = wb.SheetNames;
			if (sheets.length) resolve(utils.sheet_to_json(wb.Sheets[sheets[0]]))
		}
		reader.onerror = (error) => reject(error);
		reader.readAsArrayBuffer(file);
	});
}

export const sortByDate = (sortType, key, payloadData) => {
	if (sortType === SortOptions.LATEST) return payloadData.sort((a, b) => parseDate(b[key]) - parseDate(a[key]));
	else return payloadData.sort((a, b) => parseDate(a[key]) - parseDate(b[key]));
}

export const togglePermissionSelection = (data, value) => {
	Object.keys(data).forEach((item) => {
		if (typeof data[item] === 'boolean' || typeof data[item] === 'string') {
			data[item] = value;
		}
		typeof data[item] === 'object' && data[item] !== null && togglePermissionSelection(data[item], value);
	});
	return data;
}

export const toTitleCase = str => {
	if (!str || str.length === 0) return "";
	str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
	return str.trim().split(' ').map(word => (
		word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
	)).join(' ')
}

export const yearPicker = startRange => {
	const date = parseDate();
	let array = [];
	while (startRange <= date.getFullYear()) {
		const newObject = { label: startRange, value: startRange.toString() }
		array.unshift(newObject);
		startRange++
	}
	return array;
};
