import {Provider} from "react-redux";
import { createRoot } from "react-dom/client";

import "./index.css";
import "./assets/scss/style.scss";

import App from "./App";
import store from "./store/index";
import reportWebVitals from "./reportWebVitals";
import {ToastContextProvider} from "./components/toasts/ToastContext";


const AppRoot = () => {

	return (
		<Provider store={store}>
			<ToastContextProvider position="top-right">
				<App/>
			</ToastContextProvider>
		</Provider>
	)
}

const root = createRoot(document.getElementById('root'));
root.render(<AppRoot/>);
reportWebVitals();