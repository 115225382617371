import {InputValidator} from "../helpers/constants"
import {processSheet} from "../helpers/logicHelper";

export const NUMBER_REGEX = new RegExp(/.*\d/);
export const LENGTH_REGEX = new RegExp(/.{8,}$/);
export const UPPERCASE_REGEX = new RegExp(/[A-Z]/);
export const LOWERCASE_REGEX = new RegExp(/[a-z]/);
export const SPECIAL_CHARS_REGEX = new RegExp(/.*[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/);

export const emailValidator = email => {
    const pattern = /\S+@\S+\.\S+/, validEmail = email.toLowerCase().replace(/ /g, "");
    const validState = pattern.test(validEmail) ? InputValidator.VALID : InputValidator.INVALID;
    return {validState, validEmail}
};

export const phoneValidator = phone => {
    const pattern = /^\+\d{1,3}(\(\d+\))?\d{7,14}$/; //REGEX FOR PHONE WITH BRACKETS
     //REGEX FOR NUMBER WITHOUT BRACKETS /^\+\d{1,3}\d{7,14}$/;
    return pattern.test(phone) ? InputValidator.VALID : InputValidator.INVALID;
}

export const removeSpecialCharacters = (text) => {
    const pattern = /[^A-Za-z0-9]+/g;
    return text.replace(pattern, '');
};

export const validateIfFloat = event => {
    if (event.charCode === 101 || event.charCode === 45) event.preventDefault();
}

export const validateIfInteger = event => {
    if (event.charCode === 101 || (event.charCode === 46 || event.charCode === 45)) event.preventDefault();
}

export const validateExcelSheet = async(event, size) => {
    const allowedExtensions = ["csv", "CSV", "xls", "XLS", "xlsx", "XLSX"];
    const sizeLimit = size ? size : 2007152;
    const selectedFileSize = event.target.files[0].size || "";
    const selectedFileName = event.target.files[0].name || null;
    const fileExtension = selectedFileName.split(".").pop();


    if (typeof selectedFileSize === 'undefined') return {fileError: "There is an error uploading a file"}
    else if (event.target.files[0] && !allowedExtensions.includes(fileExtension)) return {fileError: "File must be an excel document with extension 'xlsx' or 'xls' only"};
    else if (selectedFileSize > sizeLimit) return {fileError: "File size too large"};
    else {
        let formattedFile = await processSheet(event.target.files[0]);
        return {fileData: formattedFile, fileName: selectedFileName, fileType: fileExtension}
    }
}
