import { setKey } from "./logicHelper";
import EncryptionHelper from "./encryptionHelper";

const CookieHelper = {

  get: key => {
    const updatedKey = setKey(key);
    const dataEncrypted = localStorage.getItem(updatedKey);
    return dataEncrypted !== 'null' ? EncryptionHelper.decrypt(dataEncrypted) : '';
  },

  set: (key, data) => {
    const dataEncrypted = EncryptionHelper.encrypt(data);
    localStorage.setItem(setKey(key), dataEncrypted);
  },

  remove: key => {
    localStorage.removeItem(setKey(key))
  }

}

export default CookieHelper;