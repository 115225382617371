import { Loading } from "../../utils/helpers/constants";
import { formatRoles } from "../../utils/helpers/logicHelper";
import * as UserConstants from "../constants/user.constant";


const initialState = {
	businessInformation: {},
	loadingBusinessInformation: Loading.INITIAL,

	locations: [],
	locationsCount: "",
	loadingLocations: Loading.INITIAL,

	notes: [],
	NotesCount: "",
	loadingNotes: Loading.INITIAL,


	profiles: [],
	profile: {},
	profilesCount: "",
	loadingProfile: Loading.INITIAL,
	loadingProfiles: Loading.INITIAL,

	adminRoles: [],
	clientRoles: [],
	role: {},
	adminRolesCount: "",
	clientRolesCount: "",
	loadingRole: Loading.INITIAL,
	loadingAdminRoles: Loading.INITIAL,
	loadingClientRoles: Loading.INITIAL,

	roleManagers: [],
	roleManager: {},
	roleManagersCount: "",
	loadingRoleManager: Loading.INITIAL,
	loadingRoleManagers: Loading.INITIAL,

	subscriptionUsers: [],
	subscriptionUsersCount: "",
	loadingSubscriptionUsers: Loading.INITIAL,

	tenants: [],
	tenant: {},
	tenantsCount: "",
	loadingTenant: Loading.INITIAL,
	loadingTenants: Loading.INITIAL,
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {

		case UserConstants.FETCH_BUSINESS_INFORMATION_REQUEST:
			return { ...state, loadingBusinessInformation: Loading.FETCHING };

		case UserConstants.FETCH_BUSINESS_INFORMATION_SUCCESS:
			let businessInformation = (Array.isArray(action.payload) && action.payload.length > 0) ? action.payload[0] : Object.entries(action.payload).length > 0 ? action.payload : {};
			return {
				...state,
				loadingBusinessInformation: Loading.SUCCESS,
				businessInformation,
			};

		case UserConstants.FETCH_BUSINESS_INFORMATION_FAILURE:
			return {
				...state,
				loadingBusinessInformation: Loading.ERROR,
				businessInformation: initialState.businessInformation,
			};

		case UserConstants.FETCH_LOCATION_REQUEST:
			return { ...state, loadingLocations: Loading.FETCHING };

		case UserConstants.FETCH_LOCATION_SUCCESS:
			return {
				...state,
				loadingLocations: Loading.SUCCESS,
				locations: action.payload.data,
				locationsCount: action.payload.count
			};

		case UserConstants.FETCH_LOCATION_FAILURE:
			return {
				...state,
				loadingLocations: Loading.ERROR,
				locations: initialState.locations,
				locationsCount: initialState.locationsCount
			};

		case UserConstants.FETCH_PROFILE_REQUEST:
			return { ...state, loadingProfile: Loading.FETCHING };

		case UserConstants.FETCH_PROFILE_SUCCESS:
			return {
				...state,
				loadingProfile: Loading.SUCCESS,
				profile: action.payload.data,
			};

		case UserConstants.FETCH_PROFILE_FAILURE:
			return {
				...state,
				loadingProfile: Loading.ERROR,
				profile: initialState.profiles,
			};

			case UserConstants.FETCH_PROFILES_REQUEST:
			return { ...state, loadingProfiles: Loading.FETCHING };

		case UserConstants.FETCH_PROFILES_SUCCESS:
			return {
				...state,
				loadingProfiles: Loading.SUCCESS,
				profiles: action.payload.data,
				profilesCount: action.payload.count
			};

		case UserConstants.FETCH_PROFILES_FAILURE:
			return {
				...state,
				loadingProfiles: Loading.ERROR,
				profiles: initialState.profiles,
				profilesCount: initialState.profilesCount
			};

		case UserConstants.FETCH_ROLE_REQUEST:
			return { ...state, loadingRole: Loading.FETCHING };
		case UserConstants.FETCH_ROLE_SUCCESS:
			return {
				...state,
				loadingRole: Loading.SUCCESS,
				role: action.payload.data,
			};
		case UserConstants.FETCH_ROLE_FAILURE:
			return {
				...state,
				loadingRole: Loading.ERROR,
				role: initialState.role,
			};

		case UserConstants.FETCH_ADMIN_ROLES_REQUEST:
			return { ...state, loadingAdminRoles: Loading.FETCHING };

		case UserConstants.FETCH_ADMIN_ROLES_SUCCESS:
			const adminRoles = formatRoles(action.payload.data);
			return {
				...state,
				loadingAdminRoles: Loading.SUCCESS,
				adminRoles,
				adminRolesCount: action.payload.count
			};
		case UserConstants.FETCH_ADMIN_ROLES_FAILURE:
			return {
				...state,
				loadingAdminRoles: Loading.ERROR,
				adminRoles: initialState.adminRoles,
				adminRolesCount: initialState.adminRolesCount
			};
			case UserConstants.FETCH_CLIENT_ROLES_REQUEST:
			return { ...state, loadingClientRoles: Loading.FETCHING };

		case UserConstants.FETCH_CLIENT_ROLES_SUCCESS:
			const clientRoles = formatRoles(action.payload.data);
			return {
				...state,
				loadingClientRoles: Loading.SUCCESS,
				clientRoles,
				clientRolesCount: action.payload.count
			};
		case UserConstants.FETCH_CLIENT_ROLES_FAILURE:
			return {
				...state,
				loadingClientRoles: Loading.ERROR,
				clientRoles: initialState.clientRoles,
				clientRolesCount: initialState.clientRolesCount
			};

		case UserConstants.FETCH_ROLE_MANAGER_REQUEST:
			return { ...state, loadingRoleManager: Loading.FETCHING };

		case UserConstants.FETCH_ROLE_MANAGER_SUCCESS:
			return {
				...state,
				loadingRoleManager: Loading.SUCCESS,
				roleManager: action.payload.data,
			};

		case UserConstants.FETCH_ROLE_MANAGER_FAILURE:
			return {
				...state,
				loadingRoleManager: Loading.ERROR,
				roleManager: initialState.roleManager,
			};

			case UserConstants.FETCH_ROLE_MANAGERS_REQUEST:
			return { ...state, loadingRoleManagers: Loading.FETCHING };

		case UserConstants.FETCH_ROLE_MANAGERS_SUCCESS:
			return {
				...state,
				loadingRoleManagers: Loading.SUCCESS,
				roleManagers: action.payload.data,
				roleManagersCount: action.payload.count
			};

		case UserConstants.FETCH_ROLE_MANAGERS_FAILURE:
			return {
				...state,
				loadingRoleManagers: Loading.ERROR,
				roleManagers: initialState.roleManagers,
				roleManagersCount: initialState.roleManagersCount
			};

		case UserConstants.FETCH_SUBSCRIPTION_USERS_REQUEST:
			return { ...state, loadingSubscriptionUsers: Loading.FETCHING };

		case UserConstants.FETCH_SUBSCRIPTION_USERS_SUCCESS:
			return {
				...state,
				loadingSubscriptionUsers: Loading.SUCCESS,
				subscriptionUsers: action.payload.data,
				subscriptionUsersCount: action.payload.count,
			};

		case UserConstants.FETCH_SUBSCRIPTION_USERS_FAILURE:
			return {
				...state,
				loadingSubscriptionUsers: Loading.ERROR,
				subscriptionUsers: initialState.subscriptionUsers,
				subscriptionUsersCount: initialState.subscriptionUsersCount,
			};

		case UserConstants.FETCH_TENANTS_REQUEST:
			return { ...state, loadingTenants: Loading.FETCHING };

		case UserConstants.FETCH_TENANTS_SUCCESS:
			return {
				...state,
				loadingTenants: Loading.SUCCESS,
				tenants: action.payload.data,
				tenantsCount: action.payload.count
			};

		case UserConstants.FETCH_TENANTS_FAILURE:
			return {
				...state,
				loadingTenants: Loading.ERROR,
				tenants: initialState.tenants,
				tenantsCount: initialState.tenantsCount
			};


		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
};

export default userReducer;
