export const AppInfo = {
	APP_NAME: "Phaneum Backoffice",
	ORG_NAME: 'Hobia HQ',
	LOCALHOST: "localhost",
	VERCEL_HOSTNAME: "phaneum-fe-phaneum-dev.vercel.app",
	STAGING_HOSTNAME: "saasmgr.phaneumdev.com",
	PRODUCTION_HOSTNAME: "saasmgr.phaneum.com",
	BACKOFFICE_STAGING_BASEURL: "https://dev.backoffice.phaneum.com/api/phaneum/gateway",
	BACKOFFICE_PRODUCTION_BASEURL: "https://dev.backoffice.phaneum.com/api/phaneum/gateway",
};

export const AppKeys = {
	SETTINGS_TABS: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-53771Ng57aB54c71v3k3v",
	BUSINESS_INFO_TABS: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-bn51N3551N407aB54c71v3k3v",
	KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-k3v3484c97H1anW4k43m3-mhU486Gpo42r5yPkf152r5hU486Gpo9CCSxfC5D30fn89CCR",
	TOKEN_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-70k3Nk3484c97H1anW4k43m3-mhU486Gpo42r5yPkf152r5hU486Gpo9CCSxfC5D30fn89CCR70k3Nk",
	USER_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-n4Vk3484c97H1anW4k43m3-mhU4f152r5hU486Gpo9CC86Gpo42r5yPkSxfC5D30fn89CCRv53rk3Y",
	THEME_KEY: "94aU3uMBac0ff1c3-40b1a4Qd45hb04rd-7H3m3k3484c97H1anW4k43m3-m52r5hdjR45hU486Gpo9CCpo42r5yPkSxfC5D30fn89CCR7h3w3k3v",
	COOKIE_EXPIRY_PERIOD: 30,
};

/*** ARRANGED IN ALPHABETICAL ORDER ***/
export const ActionPromptOption = {
	LOGOUT: "LOGOUT",
	ADD_NOTE: "ADD_NOTE",
	RENEW_PLAN: "RENEW_PLAN",
	DELETE_LOGO: "DELETE_LOGO",
	DELETE_USER: "DELETE_USER",
	SAVE_CHANGES: "SAVE_CHANGES",
	CUSTOMERS_EXPORT: "CUSTOMERS_EXPORT",
	DELETE_BUSINESS_LOCATION: "DELETE_BUSINESS_LOCATION",
}

export const AddonOptions = {
	USER: "USER",
	DEVICE: "DEVICE",
	LOCATION: "LOCATION"
}

export const AdminRoleOptions = {
	SERVICE_STAFF: "SERVICE-STAFF",
	MANAGEMENT_STAFF: "MANAGEMENT-STAFF",
};


export const CustomTimeType = {
	MONTH: "MONTH",
	QUARTER: "QUARTER",
	HALF_YEAR: "HALF-YEAR",
	YEAR: "YEAR",
	CUSTOM_RANGE: "CUSTOM-DATE",
};

export const DefaultTimeType = {
	TODAY: "TODAY",
	YESTERDAY: "YESTERDAY",
	"CURRENT-WEEK": "CURRENT-WEEK",
	"LAST-WEEK": "LAST-WEEK",
	"CURRENT-MONTH": "THIS-MONTH",
	"LAST-MONTH": "LAST-MONTH",
	"CURRENT-YEAR": "THIS-YEAR",
	"LAST-YEAR": "LAST-YEAR",
};

export const Environment = {
	SANDBOX: "SANDBOX",
	PRODUCTION: "PRODUCTION",
}

export const InputValidator = {
	INVALID: "INVALID",
	VALID: "VALID"
};

export const Loading = {
	INITIAL: "INITIAL",
	FETCHING: "FETCHING",
	SUCCESS: "SUCCESS",
	ERROR: "ERROR",
	FAILED: "FAILED",
	NO_CONTENT: 204,
}

export const MenuLength = {
	SINGLE: "SINGLE",
	MULTIPLE: "MULTIPLE"
}
export const MenuType = {
	AUTH: "auth",
	HOME: "home",
	MESSAGING: "messaging",
	USERS: "users-management",
	TENANCY: "tenancy",
	SUBSCRIPTIONS: "subscriptions",
	SETTINGS: "general-settings",
	CONFIGS: "configurations",
}

export const MenuTypeInitialStatus = {
	"home": false,
	"users-management": false,
	"configurations": false,
	"subscriptions": false,
	"messaging": false,
	"tenancy": false,
};

export const Method = {
	CREATE: "CREATE",
	UPDATE: "UPDATE",
}

export const ModulesOptions = {
	procurement: {
		expense_manager: false,
		purchase_orders: false,
		suppliers_management: false
	},
	inventory_manager: {
		reports: false,
		stock_transfer: false,
		inventory_counts: false,
		stock_adjustments: false
	},
	resturant_manager: {
		menu_managers: false,
		resturant_orders: false,
		tables_and_region_manager: false
	},
	sales_and_payments: {
		orders: false,
		invoice: false,
		receipts: false,
		quotation: false,
		sales_return: false,
		resturant_orders: false
	},
	tenanat_management: {
		tenant_accounts: false,
		tenanat_roles_and_permissions: false
	},
	customer_management: {
		items: false,
		reports: false,
		categories: false,
		items_reports: false,
		pricing_tiers: false
	},
	banking_and_payments: {
		payments: false,
		bank_balance: false,
		expense_manager: false
	},
	user_and_subscription: {
		sunscription: false,
		user_profile: false,
		connected_devices: false
	},
	items_and_price_management: {
		items: false,
		reports: false,
		categories: false,
		items_reports: false,
		pricing_tiers: false
	}
}

export const PaginationOptions = {
	FIVE: 5,
	TEN: 10,
	TWENTY: 20,
	THIRTY: 30,
	FIFTY: 50,
	HUNDRED: 100
}

export const PermissionInitialStatus = {
	"Activate/Deactivate": false,
	"Add/Edit": false,
	"Delete": false,
	"Email": false,
	"Export": false,
	"Import": false,
	"Print": false,
	"View": false
}

export const PermissionsAdmin = {
	config_and_datastore: {
		currency: PermissionInitialStatus,
		expense_category: PermissionInitialStatus,
		geo_settings: PermissionInitialStatus,
		notification_service_settings: PermissionInitialStatus,
		payment_methods: PermissionInitialStatus,
		payment_service_settings: PermissionInitialStatus,
		taxes: PermissionInitialStatus,
		units_of_measurements: PermissionInitialStatus,
	},
	messaging: PermissionInitialStatus,
	subscription_manager: {
		addons: PermissionInitialStatus,
		plan: PermissionInitialStatus,
	},
	tenant_management: {
		tenant_accounts: PermissionInitialStatus,
		tenant_roles_and_permissions: PermissionInitialStatus,
	},
	user_management: {
		user_profile: PermissionInitialStatus,
		user_role: PermissionInitialStatus,
	},
}

export const PermissionsClient = {
	procurement: {
		suppliers_management: PermissionInitialStatus,
		purchase_orders: PermissionInitialStatus,
	},
	user_management: {
		user_profile: PermissionInitialStatus,
		user_role: PermissionInitialStatus,
		subscription: PermissionInitialStatus,
		connected_devices: PermissionInitialStatus,
	},
	general_settings: {
		business_details: PermissionInitialStatus,
		payment_methods: PermissionInitialStatus,
		locations: PermissionInitialStatus,
		"currency_&_taxes": PermissionInitialStatus,
		print_template: PermissionInitialStatus,
		email_notification: PermissionInitialStatus,
	},
	inventory_manager: {
		stock_transfer: PermissionInitialStatus,
		inventory_counts: PermissionInitialStatus,
		stock_adjustments: PermissionInitialStatus,
		reports: PermissionInitialStatus,
	},
	sales_and_payments: {
		invoice: PermissionInitialStatus,
		orders: PermissionInitialStatus,
		quotation: PermissionInitialStatus,
		receipts: PermissionInitialStatus,
		sales_return: PermissionInitialStatus,
		resturant_orders: PermissionInitialStatus,
	},
	customer_management: {
		customer: PermissionInitialStatus,
		feedback: PermissionInitialStatus,
		loyalty: PermissionInitialStatus,
	},
	banking_and_payments: {
		bank_balance: PermissionInitialStatus,
		expense_manager: PermissionInitialStatus,
		payments: PermissionInitialStatus,
	},
	items_and_price_management: {
		categories: PermissionInitialStatus,
		items: PermissionInitialStatus,
		items_reports: PermissionInitialStatus,
		pricing_tiers: PermissionInitialStatus,
		reports: PermissionInitialStatus,
	},

}

export const PlanOptions = {
	STARTER: "STARTER",
	ADVANCED: "ADVANCED",
	STANDARD: "STANDARD",
	ENTERPRISE: "ENTREPRISE"
}

export const PropsTypeOptions = {
	TAX: "TAX",
	PLAN: "PLAN",
	ROLE: "ROLE",
	STATE: "STATE",
	ADDON: "ADDON",
	COUNTY: "COUNTY",
	PROFILE: "PROFILE",
	CURRENCY: "CURRENCY",
	TRANSACTION: "TRANSACTION",
	MEASUREMENTS: "MEASUREMENTS",
	ROLE_MANAGER: "ROLE_MANAGER",
	PAYMENT_METHOD: "PAYMENT_METHOD",
	TENANT_ACCOUNT: "TENANT_ACCOUNT",
	CUSTOMER_CREDIT: "CUSTOMER_CREDIT",
	PAYMENT_SERVICE: "PAYMENT_SERVICE",
	CONNECTED_DEVICE: "CONNECTED_DEVICE",
	PURCHASE_HISTORY: "PURCHASE_HISTORY",
	EXPENSE_CATEGORY: "EXPENSE_CATEGORY",
	BUSINESS_LOCATION: "BUSINESS_LOCATION",
	CUSTOMER_MANAGEMENT: "CUSTOMER_MANAGEMENT",
	NOTIFICATION_SERVICE: "NOTIFICATION_SERVICE",
};

export const SortOptions = {
	LATEST: "LATEST",
	OLDEST: "OLDEST"
}

export const SubMenuType = {
	ADDONS: "addons",
	ADD_ADDONS: "add-addons",
	COUNTRIES: "countries",
	CURRENCIES: "currencies",
	STATES: "states",
	TAXES: "taxes",
	PAYMENT_SERVICE: "payment-service",
	NOTIFICATION_SERVICE: "notification-service",
	PAYMENT_METHOD: "payment-method",
	EXPENSE_CATEGORIES: "expense-categories",
	MEASUREMENTS: "units-of-measurement",
	TENANTS_ACCOUNT: "tenant-and-account",
	ROLE_MANAGER: "role-manager",
	PLANS: "plans",
	PROFILE: "profile",
	ROLES: "roles",
	SETTINGS: "settings",
	BILLING_HISTORY: "billing-history",
	LOGIN: "login",
	REGISTER: "register",
	RESET_PASSWORD: "reset-password",
	FORGOT_PASSWORD: "forgot-password",
	TRANSACTION: "transactions",
}

export const SubscriptionDurationOption = {
	MONTHLY: "MONTHLY",
	YEARLY: "YEARLY",
}

export const TenancyInfoNavOptions = {
	CLIENT_LICENSE: "CLIENT_LICENSE",
	BUSINESS_LOCATION: "BUSINESS_LOCATION",
	SUBSCRIPTION_USERS: "SUBSCRIPTION_USERS",
	SUBSCRIPTION_ADDON: "SUBSCRIPTION_ADDON",
	BUSINESS_INFORMATION: "BUSINESS_INFORMATION",
}

export const TenancySubscriptionNavOptions = {
	ADDONS: "ADDONS",
	DEVICES: "DEVICES",
	RENEWAL_HISTORY: "RENEWAL_HISTORY",
	MODULES_FEATURES: "MODULES_FEATURES",
	BUSINESS_LOCATION: "BUSINESS_LOCATION",
}

export const TimeType = {
	DEFAULT: "DEFAULT",
	CUSTOM: "CUSTOM",
};
