import { createContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import ToastsContainer from "./ToastsContainer";
import { addToast } from "../../store/actions/component.action";


export const ToastContext = createContext({
	success: (title, message) => { },
	error: (title, message) => { },
});


export const ToastContextProvider = ({ children, position }) => {
	const dispatch = useDispatch();
	const component = useSelector(state => state.componentReducer);

	const handleAddToast = (type, title, message) => dispatch(addToast({ title, message, type }));


	const success = (title, message) => {
		handleAddToast("success", title, message);
	};

	const error = (title, message) => {
		handleAddToast("error", title, message);
	};


	const value = { success, error };

	return (
		<ToastContext.Provider value={value}>
			<ToastsContainer toasts={component.toasts} position={position} />
			{children}
		</ToastContext.Provider>
	);
};
