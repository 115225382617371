import {configureStore} from "@reduxjs/toolkit";
import rootReducer from './reducers';
import {createLogger} from "redux-logger";
const logger = createLogger({collapsed: true});

const loggerMiddleware = () => {
	if (process.env.NODE_ENV === 'development') return [logger];
	return [];
};

const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware => getDefaultMiddleware({serializableCheck: false, immutableCheck: false}).concat(...loggerMiddleware()),
});

export default store;
