import { Loading } from "../../utils/helpers/constants";
import * as ConfigurationConstants from "../constants/configuration.constant";


const initialState = {
	businessCurrencies: [],
	businessCurrenciesCount: "",
	loadingBusinessCurrencies: Loading.INITIAL,
	businessEmailNotification: {},
	loadingBusinessEmailNotification: Loading.INITIAL,
	businessPaymentMethod: [],
	buisnessPaymentMethodCount: "",
	loadingBusinessPaymentMethod: Loading.INITIAL,
	currencies: [],
	currenciesCount: "",
	loadingCurrencies: Loading.INITIAL,
	expenseCategories: [],
	expenseCategoriesCount: "",
	loadingExpenseCategories: Loading.INITIAL,
	measurements: [],
	measurementsCount: "",
	loadingMeasurements: Loading.INITIAL,
	notifications: [],
	notificationsCount: "",
	loadingNotifications: Loading.INITIAL,
	paymentMethod: [],
	paymentMethodCount: "",
	loadingPaymentMethod: Loading.INITIAL,
	paymentService: [],
	paymentServiceInfo: {},
	paymentServiceCount: "",
	loadingPaymentService: Loading.INITIAL,
	loadingPaymentServiceInfo: Loading.INITIAL,
	printTemplate: {},
	loadingPrintTemplate: Loading.INITIAL,
	supportedCountries: [],
	supportedCountriesCount: "",
	loadingSupportedCountries: Loading.INITIAL,
	supportedStates: [],
	supportedStatesCount: "",
	loadingSupportedStates: Loading.INITIAL,
	taxes: [],
	taxesCount: "",
	loadingTaxes: Loading.INITIAL,
};

const configurationReducer = (state = initialState, action) => {
	switch (action.type) {
		case ConfigurationConstants.FETCH_BUSINESS_CURRENCIES_REQUEST:
			return { ...state, loadingBusinessCurrencies: Loading.FETCHING };

		case ConfigurationConstants.FETCH_BUSINESS_CURRENCIES_SUCCESS:
			return {
				...state,
				loadingBusinessCurrencies: Loading.SUCCESS,
				businessCurrencies: action.payload.data,
				businessCurrenciesCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_BUSINESS_CURRENCIES_FAILURE:
			return {
				...state,
				loadingBusinessCurrencies: Loading.ERROR,
				businessCurrencies: initialState.businessCurrencies,
				businessCurrenciesCount: initialState.businessCurrenciesCount
			};

		case ConfigurationConstants.FETCH_BUSINESS_EMAIL_NOTOFICATION_REQUEST:
			return { ...state, loadingBusinessEmailNotification: Loading.FETCHING };

		case ConfigurationConstants.FETCH_BUSINESS_EMAIL_NOTOFICATION_SUCCESS:
			return {
				...state,
				loadingBusinessEmailNotification: Loading.SUCCESS,
				businessEmailNotification: action.payload.data,
			};

		case ConfigurationConstants.FETCH_BUSINESS_EMAIL_NOTOFICATION_FAILURE:
			return {
				...state,
				loadingBusinessEmailNotification: Loading.ERROR,
				businessEmailNotification: initialState.currencies,
			};
		case ConfigurationConstants.FETCH_BUSINESS_PAYMENT_METHOD_REQUEST:
			return { ...state, loadingBusinessPaymentMethod: Loading.FETCHING };

		case ConfigurationConstants.FETCH_BUSINESS_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				loadingBusinessPaymentMethod: Loading.SUCCESS,
				businessPaymentMethod: action.payload.data,
				buisnessPaymentMethodCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_BUSINESS_PAYMENT_METHOD_FAILURE:
			return {
				...state,
				loadingBusinessPaymentMethod: Loading.ERROR,
				businessPaymentMethod: initialState.businessPaymentMethod,
				buisnessPaymentMethodCount: initialState.buisnessPaymentMethodCount
			};

		case ConfigurationConstants.FETCH_CURRENCIES_REQUEST:
			return { ...state, loadingCurrencies: Loading.FETCHING };

		case ConfigurationConstants.FETCH_CURRENCIES_SUCCESS:
			return {
				...state,
				loadingCurrencies: Loading.SUCCESS,
				currencies: action.payload.data,
				currenciesCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_CURRENCIES_FAILURE:
			return {
				...state,
				loadingCurrencies: Loading.ERROR,
				currencies: initialState.currencies,
				currenciesCount: initialState.currenciesCount
			};

		case ConfigurationConstants.FETCH_EXPENSE_CATEGORIES_REQUEST:
			return { ...state, loadingExpenseCategories: Loading.FETCHING };

		case ConfigurationConstants.FETCH_EXPENSE_CATEGORIES_SUCCESS:
			return {
				...state,
				loadingExpenseCategories: Loading.SUCCESS,
				expenseCategories: action.payload.data,
				expenseCategoriesCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_EXPENSE_CATEGORIES_FAILURE:
			return {
				...state,
				loadingExpenseCategories: Loading.ERROR,
				expenseCategories: initialState.expenseCategories,
				expenseCategoriesCount: initialState.expenseCategoriesCount
			};

		case ConfigurationConstants.FETCH_MEASUREMENTS_REQUEST:
			return { ...state, loadingMeasurements: Loading.FETCHING };

		case ConfigurationConstants.FETCH_MEASUREMENTS_SUCCESS:
			return {
				...state,
				loadingMeasurements: Loading.SUCCESS,
				measurements: action.payload.data,
				measurementsCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_MEASUREMENTS_FAILURE:
			return {
				...state,
				loadingMeasurements: Loading.ERROR,
				measurements: initialState.measurements,
				measurementsCount: initialState.measurementsCount
			};

		case ConfigurationConstants.FETCH_NOTIFICATIONS_REQUEST:
			return { ...state, loadingNotifications: Loading.FETCHING };

		case ConfigurationConstants.FETCH_NOTIFICATIONS_SUCCESS:
			return {
				...state,
				loadingNotifications: Loading.SUCCESS,
				notifications: action.payload.data,
				notificationsCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_NOTIFICATIONS_FAILURE:
			return {
				...state,
				loadingNotifications: Loading.ERROR,
				notifications: initialState.notifications,
				notificationsCount: initialState.notificationsCount
			};

		case ConfigurationConstants.FETCH_PAYMENT_METHOD_REQUEST:
			return { ...state, loadingPaymentMethod: Loading.FETCHING };

		case ConfigurationConstants.FETCH_PAYMENT_METHOD_SUCCESS:
			return {
				...state,
				loadingPaymentMethod: Loading.SUCCESS,
				paymentMethod: action.payload.data,
				paymentMethodCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_PAYMENT_METHOD_FAILURE:
			return {
				...state,
				loadingPaymentMethod: Loading.ERROR,
				paymentMethod: initialState.paymentMethod,
				paymentMethodCount: initialState.paymentMethodCount
			};

		case ConfigurationConstants.FETCH_PAYMENT_SERVICE_INFO_REQUEST:
			return { ...state, loadingPaymentServiceInfo: Loading.FETCHING };

		case ConfigurationConstants.FETCH_PAYMENT_SERVICE_INFO_SUCCESS:
			return {
				...state,
				loadingPaymentServiceInfo: Loading.SUCCESS,
				paymentServiceInfo: action.payload.data,
			};

		case ConfigurationConstants.FETCH_PAYMENT_SERVICE_INFO_FAILURE:
			return {
				...state,
				loadingPaymentServiceInfo: Loading.ERROR,
				paymentServiceInfo: initialState.paymentServiceInfo,
			};

		case ConfigurationConstants.FETCH_PAYMENT_SERVICE_REQUEST:
			return { ...state, loadingPaymentService: Loading.FETCHING };

		case ConfigurationConstants.FETCH_PAYMENT_SERVICE_SUCCESS:
			return {
				...state,
				loadingPaymentService: Loading.SUCCESS,
				paymentService: action.payload.data,
				paymentServiceCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_PAYMENT_SERVICE_FAILURE:
			return {
				...state,
				loadingPaymentService: Loading.ERROR,
				paymentService: initialState.paymentService,
				paymentServiceCount: initialState.paymentServiceCount
			};

		case ConfigurationConstants.FETCH_PRINT_TEMPLATE_REQUEST:
			return { ...state, loadingPrintTemplate: Loading.FETCHING };

		case ConfigurationConstants.FETCH_PRINT_TEMPLATE_SUCCESS:
			let printTemplate = (Array.isArray(action.payload) && action.payload.length > 0) ? action.payload[0] : Object.entries(action.payload).length > 0 ? action.payload : {};
			return {
				...state,
				printTemplate,
				loadingPrintTemplate: Loading.SUCCESS
			};

		case ConfigurationConstants.FETCH_PRINT_TEMPLATE_FAILURE:
			return {
				...state,
				loadingPrintTemplate: Loading.ERROR,
				printTemplate: initialState.printTemplate,
			};

		case ConfigurationConstants.FETCH_SUPPORTED_COUNTRIES_REQUEST:
			return { ...state, loadingSupportedCountries: Loading.FETCHING };

		case ConfigurationConstants.FETCH_SUPPORTED_COUNTRIES_SUCCESS:
			return {
				...state,
				loadingSupportedCountries: Loading.SUCCESS,
				supportedCountries: action.payload.data,
				supportedCountriesCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_SUPPORTED_COUNTRIES_FAILURE:
			return {
				...state,
				loadingSupportedCountries: Loading.ERROR,
				supportedCountries: initialState.supportedCountries,
				supportedCountriesCount: initialState.supportedCountriesCount
			};

		case ConfigurationConstants.FETCH_SUPPORTED_STATES_REQUEST:
			return { ...state, loadingSupportedStates: Loading.FETCHING };

		case ConfigurationConstants.FETCH_SUPPORTED_STATES_SUCCESS:
			return {
				...state,
				loadingSupportedStates: Loading.SUCCESS,
				supportedStates: action.payload.data,
				supportedStatesCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_SUPPORTED_STATES_FAILURE:
			return {
				...state,
				loadingSupportedStates: Loading.ERROR,
				supportedStates: initialState.supportedStates,
				supportedStatesCount: initialState.supportedStatesCount
			};


		case ConfigurationConstants.FETCH_TAXES_REQUEST:
			return { ...state, loadingTaxes: Loading.FETCHING };

		case ConfigurationConstants.FETCH_TAXES_SUCCESS:
			return {
				...state,
				loadingTaxes: Loading.SUCCESS,
				taxes: action.payload.data,
				taxesCount: action.payload.count
			};

		case ConfigurationConstants.FETCH_TAXES_FAILURE:
			return {
				...state,
				loadingTaxes: Loading.ERROR,
				taxes: initialState.taxes,
				taxesCount: initialState.taxesCount
			};

		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
}

export default configurationReducer;

