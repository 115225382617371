/*** ARRANGED IN ALPHABETICAL ORDER ***/

export const FETCH_BUSINESS_INFORMATION_REQUEST = "FETCH_BUSINESS_INFORMATION_REQUEST";
export const FETCH_BUSINESS_INFORMATION_SUCCESS = "FETCH_BUSINESS_INFORMATION_SUCCESS";
export const FETCH_BUSINESS_INFORMATION_FAILURE = "FETCH_BUSINESS_INFORMATION_FAILURE";

export const FETCH_LOCATION_REQUEST = "FETCH_LOCATION_REQUEST";
export const FETCH_LOCATION_SUCCESS = "FETCH_LOCATION_SUCCESS";
export const FETCH_LOCATION_FAILURE = "FETCH_LOCATION_FAILURE";

export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const FETCH_PROFILES_REQUEST = "FETCH_PROFILES_REQUEST";
export const FETCH_PROFILES_SUCCESS = "FETCH_PROFILES_SUCCESS";
export const FETCH_PROFILES_FAILURE = "FETCH_PROFILES_FAILURE";

export const FETCH_ROLE_REQUEST = "FETCH_ROLE_REQUEST";
export const FETCH_ROLE_SUCCESS = "FETCH_ROLE_SUCCESS";
export const FETCH_ROLE_FAILURE = "FETCH_ROLE_FAILURE";

export const FETCH_ADMIN_ROLES_REQUEST = "FETCH_ADMIN_ROLES_REQUEST";
export const FETCH_ADMIN_ROLES_SUCCESS = "FETCH_ADMIN_ROLES_SUCCESS";
export const FETCH_ADMIN_ROLES_FAILURE = "FETCH_ADMIN_ROLES_FAILURE";

export const FETCH_CLIENT_ROLES_REQUEST = "FETCH_CLIENT_ROLES_REQUEST";
export const FETCH_CLIENT_ROLES_SUCCESS = "FETCH_CLIENT_ROLES_SUCCESS";
export const FETCH_CLIENT_ROLES_FAILURE = "FETCH_CLIENT_ROLES_FAILURE";

export const FETCH_ROLE_MANAGER_REQUEST = "FETCH_ROLE_MANAGER_REQUEST";
export const FETCH_ROLE_MANAGER_SUCCESS = "FETCH_ROLE_MANAGER_SUCCESS";
export const FETCH_ROLE_MANAGER_FAILURE = "FETCH_ROLE_MANAGER_FAILURE";

export const FETCH_ROLE_MANAGERS_REQUEST = "FETCH_ROLE_MANAGERS_REQUEST";
export const FETCH_ROLE_MANAGERS_SUCCESS = "FETCH_ROLE_MANAGERS_SUCCESS";
export const FETCH_ROLE_MANAGERS_FAILURE = "FETCH_ROLE_MANAGERS_FAILURE";

export const FETCH_SUBSCRIPTION_USERS_REQUEST = "FETCH_SUBSCRIPTION_USERS_REQUEST";
export const FETCH_SUBSCRIPTION_USERS_SUCCESS = "FETCH_SUBSCRIPTION_USERS_SUCCESS";
export const FETCH_SUBSCRIPTION_USERS_FAILURE = "FETCH_SUBSCRIPTION_USERS_FAILURE";

export const FETCH_TENANTS_REQUEST = "FETCH_TENANTS_REQUEST";
export const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
export const FETCH_TENANTS_FAILURE = "FETCH_TENANTS_FAILURE";
