import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import componentReducer from "./component.reducer";
import subscriptionReducer from "./subscription.reducer";
import configurationReducer from "./configuration.reducer";

const rootReducer = combineReducers({
	authReducer,
	userReducer,
	componentReducer,
	subscriptionReducer,
	configurationReducer,
});

export default rootReducer;