import { useEffect, useRef, useState } from "react";
import { Image } from "react-bootstrap";
import { useDispatch } from "react-redux";

import { ToastTypes } from "../../utils/helpers/objectHelper";
import { removeToast } from "../../store/actions/component.action";


const Toast = ({ title, message, type, id }) => {
	const dispatch = useDispatch();
	const toastType = ToastTypes[type];
	const timerID = useRef(null);
	const progressRef = useRef(null);
	const [dismissed, setDismissed] = useState(false);


	useEffect(() => {
		timerID.current = setTimeout(() => {
			handleDismiss();
		}, 5000);

		return () => {
			clearTimeout(timerID.current);
		};
	}, []);

	const handleDismiss = () => {
		setDismissed(true);
		setTimeout(() => {
			dispatch(removeToast(id))
		}, 5000);
	};

	const handleMouseEnter = () => {
		clearTimeout(timerID.current);
		progressRef.current.style.animationPlayState = "paused";
	};

	const handleMouseLeave = () => {
		const remainingTime = (progressRef.current.offsetWidth / progressRef.current.parentElement.offsetWidth) * 5000;
		progressRef.current.style.animationPlayState = "running";

		timerID.current = setTimeout(() => {
			handleDismiss();
		}, remainingTime);
	};

	return (
		<div className={`custom-toast ${toastType.borderClass} ${dismissed ? "custom-toast-dismissed" : ""}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}>
			<div className="row">
				<div className="col-10">
					<div className="d-flex">
						<Image src={toastType.imgSource} alt={`${type.toUpperCase()}-ICON`} loading="lazy" />
						<div>
							{title && <h6>{title}</h6>}
							{message && <p>{message}</p>}
						</div>
					</div>
				</div>
				<div className="col-2 border-start mt-1">
					<button className="btn-close" onClick={handleDismiss} />
				</div>
			</div>

			<div className="toast-progress">
				<div ref={progressRef}
					className={`progress-bar progress-bar-xs progress-bar-striped progress-bar-animated toast-progress-bar ${toastType.progressBarClass}`} />
			</div>
		</div>
	)
}

export default Toast;
