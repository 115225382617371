import Toast from './Toast';

const ToastsContainer = ({ toasts, position = "top-right" }) => {
	return (
		<div className={`custom-toast-container ${position}`}>
			{toasts.map((toast) => (
				<Toast key={toast.id} {...toast} />
			))}
		</div>
	);
};

export default ToastsContainer;
