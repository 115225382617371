import { setKey } from "./logicHelper";
import EncryptionHelper from "./encryptionHelper";

const CacheHelper = {

  get: key => EncryptionHelper.decrypt(localStorage.getItem(setKey(key))),

  set: (key, data) => {
    const dataEncrypted = EncryptionHelper.encrypt(data);
    localStorage.setItem(setKey(key), dataEncrypted);
  },

  remove: key => localStorage.removeItem(setKey(key)),

  clear: () => localStorage.clear()

};

export default CacheHelper;
