import CryptoJS from 'crypto-js';
import { AppKeys } from './constants';
import { setKey } from './logicHelper';

const EncryptionHelper = {

	encrypt: data => {
		if (!data) return null;
		else return CryptoJS.AES.encrypt(JSON.stringify(data), setKey(AppKeys.KEY));
	},

	decrypt: data => {
		if (!data) return null;
		else {
			const bytes = CryptoJS.AES.decrypt(data.toString(), setKey(AppKeys.KEY));
			return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
		}
	},
};
export default EncryptionHelper;
