import { Loading } from "../../utils/helpers/constants";
import * as AuthConstants from "../constants/auth.constant";

const initialState = {
	isAuthenticated: false,
	user: {},
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case AuthConstants.LOGIN_REQUEST:
			return { ...state, loginStatus: Loading.FETCHING };

		case AuthConstants.LOGIN_SUCCESS:
			return { ...state, isAuthenticated: true };

		case AuthConstants.LOGIN_FAILURE:
			return { ...state, loginStatus: Loading.ERROR };

		case AuthConstants.SET_LOGGED_IN_USER:
			return { ...state, user: action.payload };

		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
};

export default authReducer;
