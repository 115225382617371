/*** ARRANGED IN ALPHABETICAL ORDER ***/
export const FETCH_BUSINESS_CURRENCIES_REQUEST = "FETCH_BUSINESS_CURRENCIES_REQUEST";
export const FETCH_BUSINESS_CURRENCIES_SUCCESS = "FETCH_BUSINESS_CURRENCIES_SUCCESS";
export const FETCH_BUSINESS_CURRENCIES_FAILURE = "FETCH_BUSINESS_CURRENCIES_FAILURE";

export const FETCH_BUSINESS_EMAIL_NOTOFICATION_REQUEST = "FETCH_BUSINESS_EMAIL_NOTOFICATION_REQUEST";
export const FETCH_BUSINESS_EMAIL_NOTOFICATION_SUCCESS = "FETCH_BUSINESS_EMAIL_NOTOFICATION_SUCCESS";
export const FETCH_BUSINESS_EMAIL_NOTOFICATION_FAILURE = "FETCH_BUSINESS_EMAIL_NOTOFICATION_FAILURE"; 

export const FETCH_BUSINESS_PAYMENT_METHOD_REQUEST = "FETCH_BUSINESS_PAYMENT_METHOD_REQUEST";
export const FETCH_BUSINESS_PAYMENT_METHOD_SUCCESS = "FETCH_BUSINESS_PAYMENT_METHOD_SUCCESS";
export const FETCH_BUSINESS_PAYMENT_METHOD_FAILURE = "FETCH_BUSINESS_PAYMENT_METHOD_FAILURE";

export const FETCH_CURRENCIES_REQUEST = "FETCH_CURRENCIES_REQUEST";
export const FETCH_CURRENCIES_SUCCESS = "FETCH_CURRENCIES_SUCCESS";
export const FETCH_CURRENCIES_FAILURE = "FETCH_CURRENCIES_FAILURE";

export const FETCH_EXPENSE_CATEGORIES_REQUEST = "FETCH_EXPENSE_CATEGORIES_REQUEST";
export const FETCH_EXPENSE_CATEGORIES_SUCCESS = "FETCH_EXPENSE_CATEGORIES_SUCCESS";
export const FETCH_EXPENSE_CATEGORIES_FAILURE = "FETCH_EXPENSE_CATEGORIES_FAILURE";

export const FETCH_MEASUREMENTS_REQUEST = "FETCH_MEASUREMENTS_REQUEST";
export const FETCH_MEASUREMENTS_SUCCESS = "FETCH_MEASUREMENTS_SUCCESS";
export const FETCH_MEASUREMENTS_FAILURE = "FETCH_MEASUREMENTS_FAILURE";

export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_PAYMENT_METHOD_REQUEST = "FETCH_PAYMENT_METHOD_REQUEST";
export const FETCH_PAYMENT_METHOD_SUCCESS = "FETCH_PAYMENT_METHOD_SUCCESS";
export const FETCH_PAYMENT_METHOD_FAILURE = "FETCH_PAYMENT_METHOD_FAILURE";

export const FETCH_PAYMENT_SERVICE_INFO_REQUEST = "FETCH_PAYMENT_SERVICE_INFO_REQUEST";
export const FETCH_PAYMENT_SERVICE_INFO_SUCCESS = "FETCH_PAYMENT_SERVICE_INFO_SUCCESS";
export const FETCH_PAYMENT_SERVICE_INFO_FAILURE = "FETCH_PAYMENT_SERVICE_INFO_FAILURE";

export const FETCH_PAYMENT_SERVICE_REQUEST = "FETCH_PAYMENT_SERVICE_REQUEST";
export const FETCH_PAYMENT_SERVICE_SUCCESS = "FETCH_PAYMENT_SERVICE_SUCCESS";
export const FETCH_PAYMENT_SERVICE_FAILURE = "FETCH_PAYMENT_SERVICE_FAILURE";

export const FETCH_PRINT_TEMPLATE_REQUEST = "FETCH_PRINT_TEMPLATE_REQUEST";
export const FETCH_PRINT_TEMPLATE_SUCCESS = "FETCH_PRINT_TEMPLATE_SUCCESS";
export const FETCH_PRINT_TEMPLATE_FAILURE = "FETCH_PRINT_TEMPLATE_FAILURE";

export const FETCH_SUPPORTED_COUNTRIES_REQUEST = "FETCH_SUPPORTED_COUNTRIES_REQUEST";
export const FETCH_SUPPORTED_COUNTRIES_SUCCESS = "FETCH_SUPPORTED_COUNTRIES_SUCCESS";
export const FETCH_SUPPORTED_COUNTRIES_FAILURE = "FETCH_SUPPORTED_COUNTRIES_FAILURE";

export const FETCH_SUPPORTED_STATES_REQUEST = "FETCH_SUPPORTED_STATES_REQUEST";
export const FETCH_SUPPORTED_STATES_SUCCESS = "FETCH_SUPPORTED_STATES_SUCCESS";
export const FETCH_SUPPORTED_STATES_FAILURE = "FETCH_SUPPORTED_STATES_FAILURE";

export const FETCH_TAXES_REQUEST = "FETCH_TAXES_REQUEST";
export const FETCH_TAXES_SUCCESS = "FETCH_TAXES_SUCCESS";
export const FETCH_TAXES_FAILURE = "FETCH_TAXES_FAILURE";
