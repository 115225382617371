/*** ARRANGED IN ALPHABETICAL ORDER ***/

export const FETCH_ACTIVE_PLAN_REQUEST = "FETCH_ACTIVE_PLAN_REQUEST";
export const FETCH_ACTIVE_PLAN_SUCCESS = "FETCH_ACTIVE_PLAN_SUCCESS";
export const FETCH_ACTIVE_PLAN_FAILURE = "FETCH_ACTIVE_PLAN_FAILURE";

export const FETCH_ADDON_REQUEST = "FETCH_ADDON_REQUEST";
export const FETCH_ADDON_SUCCESS = "FETCH_ADDON_SUCCESS";
export const FETCH_ADDON_FAILURE = "FETCH_ADDON_FAILURE";

export const FETCH_ADDONS_REQUEST = "FETCH_ADDONS_REQUEST";
export const FETCH_ADDONS_SUCCESS = "FETCH_ADDONS_SUCCESS";
export const FETCH_ADDONS_FAILURE = "FETCH_ADDONS_FAILURE";

export const FETCH_CONNECTED_DEVICES_REQUEST = "FETCH_CONNECTED_DEVICES_REQUEST";
export const FETCH_CONNECTED_DEVICES_SUCCESS = "FETCH_CONNECTED_DEVICES_SUCCESS";
export const FETCH_CONNECTED_DEVICES_FAILURE = "FETCH_CONNECTED_DEVICES_FAILURE";

export const FETCH_PLAN_HISTORY_REQUEST = "FETCH_PLAN_HISTORY_REQUEST";
export const FETCH_PLAN_HISTORY_SUCCESS = "FETCH_PLAN_HISTORY_SUCCESS";
export const FETCH_PLAN_HISTORY_FAILURE = "FETCH_PLAN_HISTORY_FAILURE";

export const FETCH_PLAN_REQUEST = "FETCH_PLAN_REQUEST";
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS";
export const FETCH_PLAN_FAILURE = "FETCH_PLAN_FAILURE";

export const FETCH_PLANS_REQUEST = "FETCH_PLANS_REQUEST";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILURE = "FETCH_PLANS_FAILURE";
