import axios from "axios";
import CacheHelper from "../helpers/cacheHelper";
import TokenHelper from "../helpers/tokenHelper";
import CookieHelper from "../helpers/cookieHelper";
import { AppInfo, AppKeys, Environment, MenuType, SubMenuType } from "../helpers/constants";


export const getBaseUrlEnv = () => {
	if (typeof window !== "undefined") {
		if (
			window.location.hostname === AppInfo.LOCALHOST ||
			window.location.hostname === AppInfo.STAGING_HOSTNAME ||
			window.location.hostname === AppInfo.VERCEL_HOSTNAME
		) return Environment.SANDBOX;
		else if (window.location.hostname === AppInfo.PRODUCTION_HOSTNAME) return Environment.PRODUCTION;
	}
};

export const getBaseUrl = () => {
	let backoffice;
	if (getBaseUrlEnv() === Environment.SANDBOX) backoffice = AppInfo.BACKOFFICE_STAGING_BASEURL;
	else if (getBaseUrlEnv() === Environment.PRODUCTION) backoffice = AppInfo.BACKOFFICE_PRODUCTION_BASEURL;
	return { backoffice };
};

const instance = axios.create({ baseURL: getBaseUrl().backoffice });

instance.interceptors.request.use(config => {
	const token = TokenHelper.getToken(),
		domainSuffix = getBaseUrlEnv() === Environment.PRODUCTION ? '.phaneum.com' : '.phaneumdev.com',
		domain = `admin${domainSuffix}`
	if (TokenHelper.checkIfLoggedIn()) config.headers = {
		'Content-Type': 'application/json',
		Authorization: `JWT ${token}`,
		tenantURL: domain,
		...config.headers
	};
	else config.headers = { 'Content-Type': 'application/json', ...config.headers };
	return config;
}, error => Promise.reject(error));

instance.interceptors.response.use(response => response, error => {
	const token = TokenHelper.getToken();
	if (error && error.response && error.response.status && error.response.status === 403) window.location.href = '/403';
	else if ((error && error.response && error.response.status && error.response.status === 401) && token) {
		CookieHelper.remove(AppKeys.KEY);
		CookieHelper.remove(AppKeys.USER_TYPE);
		CacheHelper.clear();
		window.location.href = `/${MenuType.AUTH}/${SubMenuType.LOGIN}/`;
	} else return error.response;
});

export { instance };
