import { AppKeys } from './constants';
import CookieHelper from "./cookieHelper";

const TokenHelper = {

	checkIfLoggedIn() {
		const TOKEN = CookieHelper.get(AppKeys.TOKEN_KEY);
		if (!TOKEN) return false;
		return !!TOKEN;
	},

	saveToken(token, initDuration) {
		const duration = initDuration ? initDuration : AppKeys.COOKIE_EXPIRY_PERIOD;
		CookieHelper.set(AppKeys.TOKEN_KEY, token, duration);
	},

	getToken() {
		return CookieHelper.get(AppKeys.TOKEN_KEY);
	},

	saveUser(data, initDuration) {
		const duration = initDuration ? initDuration : AppKeys.COOKIE_EXPIRY_PERIOD;
		CookieHelper.set(AppKeys.USER_KEY, data, duration);
	},

	getUser() {
		return CookieHelper.get(AppKeys.USER_KEY);
	},

	saveBusinessInfoTab(data) {
		CookieHelper.set(AppKeys.BUSINESS_INFO_TABS, data, AppKeys.COOKIE_EXPIRY_PERIOD);
	},

	getBusinessInfoTab() {
		return CookieHelper.get(AppKeys.BUSINESS_INFO_TABS);
	},

	saveSettingsTab(data) {
		CookieHelper.set(AppKeys.SETTINGS_TABS, data, AppKeys.COOKIE_EXPIRY_PERIOD);
	},

	getSettingsTab() {
		return CookieHelper.get(AppKeys.SETTINGS_TABS);
	},

}

export default TokenHelper;
