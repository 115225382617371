import {Loading} from "../../utils/helpers/constants";
import * as SubscriptionConstants from "../constants/subscription.constant.js";


const initialState = {
	activePlan: {},
	loadingActivePlan: Loading.INITIAL,
	addons: [],
	addon: {},
	addonsCount: "",
	loadingAddon: Loading.INITIAL,
	loadingAddons: Loading.INITIAL,
	connectedDevice: [],
	connectedDeviceCount: "",
	loadingConnectedDevice: Loading.INITIAL,
	plans: [],
	plan: {},
	plansCount: "",
	loadingPlan: Loading.INITIAL,
	loadingPlans: Loading.INITIAL,
	planHistory: [],
	planHistoryCount: "",
	loadingPlanHistory: Loading.INITIAL,
};

const subscriptionReducer = (state = initialState, action) => {
	switch(action.type) {

		case SubscriptionConstants.FETCH_ACTIVE_PLAN_REQUEST:
			return {...state, loadingActivePlan: Loading.FETCHING};

		case SubscriptionConstants.FETCH_ACTIVE_PLAN_SUCCESS:
			let activePlan = (action.payload.data.length < 1) ? {} : action.payload.data[0];
			return {
				...state,
				loadingActivePlan: Loading.SUCCESS,
				activePlan
			};

		case SubscriptionConstants.FETCH_ACTIVE_PLAN_FAILURE:
			return {
				...state,
				loadingActivePlan: Loading.ERROR,
				activePlan: initialState.activePlan,
			};

		case SubscriptionConstants.FETCH_ADDON_REQUEST:
			return {...state, loadingAddon: Loading.FETCHING};

		case SubscriptionConstants.FETCH_ADDON_SUCCESS:
			return {
				...state,
				loadingAddon: Loading.SUCCESS,
				addon: action.payload.data,
			};

		case SubscriptionConstants.FETCH_ADDON_FAILURE:
			return {
				...state,
				loadingAddon: Loading.ERROR,
				addon: initialState.addon,
			};

		case SubscriptionConstants.FETCH_ADDONS_REQUEST:
			return {...state, loadingAddons: Loading.FETCHING};

		case SubscriptionConstants.FETCH_ADDONS_SUCCESS:
			return {
				...state,
				loadingAddons: Loading.SUCCESS,
				addons: action.payload.data,
				addonsCount: action.payload.count
			};

		case SubscriptionConstants.FETCH_ADDONS_FAILURE:
			return {
				...state,
				loadingAddons: Loading.ERROR,
				addons: initialState.addons,
				addonsCount: initialState.addonsCount
			};


		case SubscriptionConstants.FETCH_CONNECTED_DEVICES_REQUEST:
			return {...state, loadingConnectedDevice: Loading.FETCHING};

		case SubscriptionConstants.FETCH_CONNECTED_DEVICES_SUCCESS:
			return {
				...state,
				loadingConnectedDevice: Loading.SUCCESS,
				connectedDevice: action.payload.data,
				connectedDeviceCount: action.payload.count
			};

		case SubscriptionConstants.FETCH_CONNECTED_DEVICES_FAILURE:
			return {
				...state,
				loadingConnectedDevice: Loading.ERROR,
				connectedDevice: initialState.connectedDevice,
				connectedDeviceCount: initialState.connectedDeviceCount
			};

		case SubscriptionConstants.FETCH_PLAN_HISTORY_REQUEST:
			return {...state, loadingPlanHistory: Loading.FETCHING};

		case SubscriptionConstants.FETCH_PLAN_HISTORY_SUCCESS:
			return {
				...state,
				loadingPlanHistory: Loading.SUCCESS,
				planHistory: action.payload.data,
				planHistoryCount: action.payload.count
			};

		case SubscriptionConstants.FETCH_PLAN_HISTORY_FAILURE:
			return {
				...state,
				loadingPlanHistory: Loading.ERROR,
				planHistory: initialState.planHistory,
				plansCount: initialState.plansCount
			};

		case SubscriptionConstants.FETCH_PLAN_REQUEST:
			return {...state, loadingPlan: Loading.FETCHING};

		case SubscriptionConstants.FETCH_PLAN_SUCCESS:
			return {
				...state,
				loadingPlan: Loading.SUCCESS,
				plan: action.payload.data,
			};

		case SubscriptionConstants.FETCH_PLAN_FAILURE:
			return {
				...state,
				loadingPlan: Loading.ERROR,
				plan: initialState.plan,
			};

		case SubscriptionConstants.FETCH_PLANS_REQUEST:
			return {...state, loadingPlans: Loading.FETCHING};

		case SubscriptionConstants.FETCH_PLANS_SUCCESS:
			return {
				...state,
				loadingPlans: Loading.SUCCESS,
				plans: action.payload.data,
				plansCount: action.payload.count
			};

		case SubscriptionConstants.FETCH_PLANS_FAILURE:
			return {
				...state,
				loadingPlans: Loading.ERROR,
				plans: initialState.plans,
				plansCount: initialState.plansCount
			};

		case 'RESET_STATE':
			return initialState;

		default:
			return state;
	}
}

export default subscriptionReducer;
