import ErrorSVG from "../../assets/images/svgs/toast-error.svg";
import SuccessSVG from "../../assets/images/svgs/toast-success.svg";
import {
	LENGTH_REGEX, LOWERCASE_REGEX, NUMBER_REGEX, SPECIAL_CHARS_REGEX, UPPERCASE_REGEX
} from "../services/validator.service";
import {
	 AddonOptions, AdminRoleOptions, CustomTimeType, DefaultTimeType, SortOptions, SubscriptionDurationOption, TimeType 
	} from "./constants";


/*** ARRANGED IN ALPHABETICAL ORDER ***/

export const AddonTypes = [
	{ label: "User", value: AddonOptions.USER },
	{ label: "Device", value: AddonOptions.DEVICE },
	{ label: "Location", value: AddonOptions.LOCATION },
];

export const AdminUsersRoles = [
	{ label: "Service Staff", value: AdminRoleOptions.SERVICE_STAFF },
	{ label: "Management Staff", value: AdminRoleOptions.MANAGEMENT_STAFF },
];
export const AnalyticsTimeRange = [
	{ label: 'Today', value: DefaultTimeType.TODAY, type: TimeType.DEFAULT },
	{ label: 'Yesterday', value: DefaultTimeType.YESTERDAY, type: TimeType.DEFAULT },
	{ label: 'Current Week', value: DefaultTimeType["CURRENT-WEEK"], type: TimeType.DEFAULT },
	{ label: 'Last Week', value: DefaultTimeType["LAST-WEEK"], type: TimeType.DEFAULT },
	{ label: 'Current Month', value: DefaultTimeType["CURRENT-MONTH"], type: TimeType.DEFAULT },
	{ label: 'Last Month', value: DefaultTimeType["LAST-MONTH"], type: TimeType.DEFAULT },
	{ label: 'Current Year', value: DefaultTimeType["CURRENT-YEAR"], type: TimeType.DEFAULT },
	{ label: 'Last Year', value: DefaultTimeType["LAST-YEAR"], type: TimeType.DEFAULT },
	{ label: 'Month', value: CustomTimeType.MONTH, type: TimeType.CUSTOM },
	{ label: 'Quarter', value: CustomTimeType.QUARTER, type: TimeType.CUSTOM },
	{ label: 'Half Year', value: CustomTimeType.HALF_YEAR, type: TimeType.CUSTOM },
	{ label: 'Year', value: CustomTimeType.YEAR, type: TimeType.CUSTOM },
	{ label: 'Custom Range', value: CustomTimeType.CUSTOM_RANGE, type: TimeType.CUSTOM },
];

export const FrequencyType = [
	{ label: "Monthly", value: SubscriptionDurationOption.MONTHLY },
	{ label: "Yearly", value: SubscriptionDurationOption.YEARLY },
];

export const HalfYearOptions = [
	{ label: "First Half (January to June)", value: "Q1" },
	{ label: "Second Half (July to December)", value: "Q2" },
];

export const MonthOptions = [
	{ label: "January", value: "JANUARY" },
	{ label: "February", value: "FEBRUARY" },
	{ label: "March", value: "MARCH" },
	{ label: "April", value: "APRIL" },
	{ label: "May", value: "MAY" },
	{ label: "June", value: "JUNE" },
	{ label: "July", value: "JULY" },
	{ label: "August", value: "AUGUST" },
	{ label: "September", value: "SEPTEMBER" },
	{ label: "October", value: "OCTOBER" },
	{ label: "November", value: "NOVEMBER" },
	{ label: "December", value: "DECEMBER" },
];

export const NotificationServiceType = [
	{ label: "Email Notification Service", value: "EMAIL" },
	{ label: "SMS Notification Service", value: "SMS" },
];

export const PaginationSize = [
	{ label: "Items to display", value: "Items to display", isDisabled: true },
	{ label: "5", value: 5 },
	{ label: "10", value: 10 },
	{ label: "20", value: 20 },
	{ label: "30", value: 30 },
	{ label: "50", value: 50 },
	{ label: "100", value: 100 },
];

export const PasswordRules = [
	{ index: 1, label: "Number (123...)", pattern: NUMBER_REGEX },
	{ index: 2, label: "8-12 characters long", pattern: LENGTH_REGEX },
	{ index: 3, label: "Uppercase letter (ABC..)", pattern: UPPERCASE_REGEX },
	{ index: 4, label: "Small letter (abc...)", pattern: LOWERCASE_REGEX },
	{ index: 5, label: "Symbol (!@%...)", pattern: SPECIAL_CHARS_REGEX },
];

export const PaymentMethodType = [
	{ label: "Cash", value: "CASH" },
	{ label: "Bank Transfer", value: "BANK_TRANSFER" },
	{ label: "Payment Terminal", value: "PAYMENT_TERMINAL" },
];

export const Plans = [
	{
		label: "User and Subscription",
		name: "user_and_subscription",
		items: [
			{ label: "Subscription", name: "sunscription" },
			{ label: "User Profile", name: "user_profile" },
			{ label: "Connected Devices", name: "connected_devices" },
		]
	},
	{
		label: "Tenant Management",
		name: "tenanat_management",
		items: [
			{ label: "Tenant Accounts", name: "tenant_accounts" },
			{ label: "Tenant Roles and Permissions", name: "tenanat_roles_and_permissions" },
		]
	},
	{
		label: "Sales and Payments",
		name: "sales_and_payments",
		items: [
			{ label: "Orders", name: "orders" },
			{ label: "Invoice", name: "invoice" },
			{ label: "Receipts", name: "receipts" },
			{ label: "Quotation", name: "quotation" },
			{ label: "Sales Return", name: "sales_return" },
			{ label: "Restaurant Orders", name: "resturant_orders" },
		]
	},
	{
		label: "Banking and Payments",
		name: "banking_and_payments",
		items: [
			{ label: "Payments", name: "payments" },
			{ label: "Bank Balance", name: "bank_balance" },
			{ label: "Expense Manager", name: "expense_manager" },
		]
	},
	{
		label: "Procurement",
		name: "procurement",
		items: [
			{ label: "Expense Manager", name: "expense_manager" },
			{ label: "Purchase Orders", name: "purchase_orders" },
			{ label: "Suppliers Management", name: "suppliers_management" },
		]
	},
	{
		label: "Restaurant Manager",
		name: "resturant_manager",
		items: [
			{ label: "Menu Managers", name: "menu_managers" },
			{ label: "Restaurant Orders", name: "resturant_orders" },
			{ label: "Tables and Region Manager", name: "tables_and_region_manager" },
		]
	},
	{
		label: "Inventory Manager",
		name: "inventory_manager",
		items: [
			{ label: "Reports", name: "reports" },
			{ label: "Stock Transfer", name: "stock_transfer" },
			{ label: "Inventory Counts", name: "inventory_counts" },
			{ label: "Stock Adjustments", name: "stock_adjustments" },
		]
	},
	{
		label: "Items and Price Management",
		name: "items_and_price_management",
		items: [
			{ label: "Reports", name: "reports" },
			{ label: "Categories", name: "categories" },
			{ label: "Items Report", name: "items_reports" },
			{ label: "Items", name: "items" },
			{ label: "Pricing Tiers", name: "pricing_tiers" },
		]
	},
	{
		label: "Customer Management",
		name: "customer_management",
		items: [
			{ label: "Reports", name: "reports" },
			{ label: "Categories", name: "categories" },
			{ label: "Items Report", name: "items_reports" },
			{ label: "Items", name: "items" },
			{ label: "Pricing Tiers", name: "pricing_tiers" },
		]
	}
];


export const QuarterOptions = [
	{ label: "Q1 (January to March)", value: "Q1" },
	{ label: "Q2 (April to June)", value: "Q2" },
	{ label: "Q3 (July to September)", value: "Q3" },
	{ label: "Q4 (October to December)", value: "Q4" },
];

export const Roles = {
	admin: [
		{
			label: "User Management",
			name: "user_management",
			items: [
				{ label: "User Profile", name: "user_profile" },
				{ label: "User Role", name: "user_role" },
			]
		},
		{
			label: "Tenant Management",
			name: "tenant_management",
			items: [
				{ label: "Tenant Accounts", name: "tenant_accounts" },
				{ label: "Tenant Roles & Permissions", name: "tenant_roles_and_permissions" },
			]
		},
		{
			label: "Subscription Manager",
			name: "subscription_manager",
			items: [
				{ label: "Plan", name: "plan" },
				{ label: "Addon", name: "addons" },
			]
		},
		{
			label: "Config & Datastore",
			name: "config_and_datastore",
			items: [
				{ label: "Geo Settings", name: "geo_settings" },
				{ label: "Taxes", name: "taxes" },
				{ label: "Payment Methods", name: "payment_methods" },
				{ label: "Currency", name: "currency" },
				{ label: "Expense Categories", name: "expense_category" },
				{ label: "Units of Measurements (UOMs)", name: "units_of_measurements" },
				{ label: "Notification Service Settings", name: "notification_service_settings" },
				{ label: "Payment Service Settings", name: "payment_service_settings" }
			]
		},
	],
	client: [
		{
			label: "User Management",
			name: "user_management",
			items: [
				{ label: "User Profile", name: "user_profile" },
				{ label: "User Role", name: "user_role" },
				{ label: "Subscription", name: "subscription" },
				{ label: "Connected Devices", name: "connected_devices" }
			]
		},
		{
			label: "Sales and Payments",
			name: "sales_and_payments",
			items: [
				{ label: "Invoice", name: "invoice" },
				{ label: "Orders", name: "orders" },
				{ label: "Quotation", name: "quotation" },
				{ label: "Receipts", name: "receipts" },
				{ label: "Sales Return", name: "sales_return" },
				{ label: "Restaurant Orders", name: "resturant_orders" }
			]
		},
		{
			label: "Banking and Payments",
			name: "banking_and_payments",
			items: [
				{ label: "Bank Balance", name: "bank_balance" },
				{ label: "Payments", name: "payments" },
				{ label: "Expense Manager", name: "expense_manager" }
			]
		},
		{
			label: "Procurement",
			name: "procurement",
			items: [
				{ label: "Suppliers Management", name: "suppliers_management" },
				{ label: "Purchase Orders", name: "purchase_orders" }
			]
		},
		{
			label: "Inventory Manager",
			name: "inventory_manager",
			items: [
				{ label: "Stock Transfer", name: "stock_transfer" },
				{ label: "Inventory Counts", name: "inventory_counts" },
				{ label: "Stock Adjustments", name: "stock_adjustments" },
				{ label: "Reports", name: "reports" },
			]
		},
		{
			label: "Items and Price Management",
			name: "items_and_price_management",
			items: [
				{ label: "Categories", name: "categories" },
				{ label: "Items", name: "items" },
				{ label: "Items Report", name: "items_reports" },
				{ label: "Pricing Tiers", name: "pricing_tiers" },
				{ label: "Reports", name: "reports" },
			]
		},
		{
			label: "Customer Management",
			name: "customer_management",
			items: [
				{ label: "Customers", name: "customer" },
				{ label: "Feedbacks", name: "feedback" },
				{ label: "Loyalty", name: "loyalty" },
			]
		},
		{
			label: "General Settings",
			name: "general_settings",
			items: [
				{ label: "Business Details", name: "business_details" },
				{ label: "Payment Methods", name: "payment_methods" },
				{ label: "Locations", name: "locations" },
				{ label: "Currency & Taxes", name: "currency_&_taxes" },
				{ label: "Print Template & Taxes", name: "print_template" },
				{ label: "Email Notifications", name: "email_notification" },
			]
		}
	],
}

export const SortFilter = [
	{ label: "Latest", value: SortOptions.LATEST },
	{ label: "Oldest", value: SortOptions.OLDEST },
];

export const StatFilter = [
	{ label: "Weekly", value: "weekly" },
	{ label: "Monthly", value: "monthly" },
	{ label: "Yearly", value: "yearly" },
]

export const Status = [
	{ label: "Activated", value: true },
	{ label: "Deactivated", value: false },
];

export const Status2 = [
	{ label: "Yes", value: true },
	{ label: "No", value: false },
];

export const StatusFilter = [
	{ label: "All", value: "ALL" },
	{ label: "Activated", value: true },
	{ label: "Deactivated", value: false },
]

export const SubscriptionMode = [
	{ label: "Retail", value: "RETAIL" },
	{ label: "Restaurant", value: "RESTURANT" },
];

export const SubscriptionPlans = [
	{ label: "Starter", value: "STARTER" },
	{ label: "Advance", value: "ADVANCED" },
	{ label: "Standard", value: "STANDARD" },
	{ label: "Enterprise", value: "ENTREPRISE" },
];

export const TaxType = [
	{ label: "Fixed", value: "FIXED" },
	{ label: "Percentage", value: "PERCENTAGE" },
];

export const ToastTypes = {
	error: {
		imgSource: ErrorSVG,
		borderClass: "border-danger-500-left",
		progressBarClass: "bg-danger-500",
	},
	success: {
		imgSource: SuccessSVG,
		borderClass: "border-success-600-left",
		progressBarClass: "bg-success-600",
	},
};